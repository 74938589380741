import { template as template_958b1cd299dd4f128ca2251ee4f0006c } from "@ember/template-compiler";
const FKControlMenuContainer = template_958b1cd299dd4f128ca2251ee4f0006c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
