import { template as template_864d12de49db4488880265b0c849a356 } from "@ember/template-compiler";
const WelcomeHeader = template_864d12de49db4488880265b0c849a356(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
