import { template as template_c8002c4cac294fb1a9c994857a9144fd } from "@ember/template-compiler";
const FKLabel = template_c8002c4cac294fb1a9c994857a9144fd(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
