import { template as template_896dea35c7884e058358194829f4191f } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_896dea35c7884e058358194829f4191f(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
